<template>
    <div>
        <loading-component></loading-component>
        <nav-bar-component :navbar="nav_bar" :title_name="my_nav_bar_name"></nav-bar-component>
        <div v-if="edit">
            <van-cell-group inset >
                <van-form>
                    <van-field
                        v-model="details.name"
                        name="name"
                        label="仓库名称"
                        placeholder="仓库名称"
                        :rules="[{ required: true, message: '请填写仓库名称' }]"
                    />
                    <select-component
                        name="region"
                        lable="包含库区"
                        :farr="all_region"
                        :fvalue_name="details.region"
                        @clk="confirm_region"
                    ></select-component>
                    <van-field
                        type="textarea"
                        v-model="details.address"
                        name="address"
                        label="仓库地址"
                        placeholder="仓库地址"
                        :rules="[{ required: true, message: '请填写仓库地址' }]"
                    />
                    <van-field
                        type="textarea"
                        v-model="details.note"
                        name="note"
                        label="仓库简介"
                        placeholder="仓库简介"
                    />
                    <select-component
                        name="enterprise"
                        lable="所属企业"
                        :farr="all_enterprise"
                        :fvalue_name="details.enterprise_name"
                        @clk="confirm_enterprise"
                    ></select-component>
                    <select-component
                        name="person"
                        lable="负责人"
                        :farr="all_staff"
                        :fvalue_name="details.person_name"
                        @clk="confirm_person"
                    ></select-component>
                    <number-keyboard-component
                        name="phone"
                        label="联系电话"
                        placeholder="请输入联系电话"
                        :fvalue="details.phone"
                        @on_input="confirm_phone"
                    ></number-keyboard-component>
                </van-form>
                <div v-if="this.id">
                    <div class="sub_button">
                        <van-button round block type="primary" @click="edit_details">提 交</van-button>
                    </div>
                    <div class="sub_button">
                        <van-button round block type="default" @click="back_details">取 消</van-button>
                    </div>
                </div>
                <div v-else>
                    <div class="sub_button">
                        <van-button round block type="primary" @click="add_details">提 交</van-button>
                    </div>
                    <div class="sub_button">
                        <van-button round block type="default" @click="$router.go(-1)">返回</van-button>
                    </div>
                </div>
            </van-cell-group>
        </div>
        <div v-else>
            <van-cell-group inset >
                <van-cell title="仓库名称" :value="details.name" />
                <van-cell title="包含库区" :value="details.region" />
                <van-cell title="仓库地址" :value="details.address" />
                <van-cell title="仓库简介" :value="details.note" />
                <van-cell title="所属企业" :value="details.enterprise_name" />
                <van-cell title="负责人" :value="details.person_name" />
                <van-cell title="联系电话" :value="details.phone" />
            </van-cell-group>
            <div class="sub_button">
                <van-button v-if="this.$_has('修改仓库')" round block type="info" @click="edit = true">修 改</van-button>
            </div>
            <div class="sub_button">
                <van-button v-if="this.$_has('删除仓库')" round block type="danger" @click="on_del">删 除</van-button>
            </div>
        </div>
    </div>
</template>

<script>
import LoadingComponent from '@/components/common/LoadingComponent.vue'
import NavBarComponent from '@/components/common/NavBarComponent.vue'
import SelectComponent from '@/components/common/SelectComponent.vue'
import NumberKeyboardComponent from '@/components/common/NumberKeyboardComponent.vue'

import { storeroom_details_request,storeroom_del_request,storeroom_edit_request,storeroom_add_request } from '@/network/WareHouse/storeroom'
import { enterprise_list_request,staff_list_request } from '@/network/list.js'

export default {
    name:'StoreroomDetailsComponent',
    data(){
        return {
            nav_bar: {
                left: true,
                right: {
                    type: '',
                    name: ''
                },
            },
            id: 0,
            details: {
                enterprise_id: '',
                enterprise_name: '',
                name: '',
                region: '',
                address: '',
                note: '',
                person_id: '',
                person_name: '',
                phone: ''
            },
            edit: false,
            all_region: [
                {id:'AB',name:'AB'},
                {id:'CD',name:'CD'},
                {id:'ABC',name:'ABC'},
                {id:'ABCD',name:'ABCD'},
                {id:'ABCE',name:'ABCE'},
            ],
            all_enterprise: [],
            all_staff: []
        }
    },
    computed:{
        my_nav_bar_name() {
            return this.edit === true ? ( this.id ? '修改仓库' : '新增仓库') : '仓库详情'
        }
    },
    methods:{
        get_storeroom_details() {
            this.$store.commit('true_loading')
            storeroom_details_request(this.id)
                .then((s) => {
                    if (s.status === 0) {
                        this.details = s.result
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    this.$toast.fail(s.msg);
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        on_del() {
            this.$dialog.confirm({
                title: '温馨提示',
                message: '是否确定删除？（一经删除，将无法再进行恢复，请谨慎操作！）',
            })
            .then(() => {
                this.$store.commit('true_loading')
                storeroom_del_request(this.id)
                        .then((s) => {
                            if (s.status === 0) {
                                this.$toast.success('删除成功!')
                                this.$router.go(-1);//返回上一层
                            } else {
                                this.$toast.fail(s.msg)
                            }
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                        .finally(() => this.$store.commit('false_loading'))
            })
            .catch(() => {});
        },
        confirm_region(e) {
            this.details.region = e.id
        },
        get_all_enterprise() {
            this.$store.commit('true_loading')
            enterprise_list_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.all_enterprise = s.result
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    this.$toast.fail(s.msg);
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        confirm_enterprise(e) {
            this.details.enterprise_id = e.id
        },
        get_all_staff() {
            this.$store.commit('true_loading')
            staff_list_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.all_staff = s.result
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        confirm_person(e) {
            this.details.person_id = e.id
        },
        confirm_phone(e) {
            this.details.phone = e*1
        },
        back_details() {
            this.get_storeroom_details()
            this.edit = false
        },
        edit_details() {
            this.$store.commit('true_loading')
            let data = {}
            data = {
                id: this.id,
                enterprise_id: this.details.enterprise_id,
                name: this.details.name,
                region: this.details.region,
                address: this.details.address,
                note: this.details.note,
                person_id: this.details.person_id,
                phone: this.details.phone
            }
            storeroom_edit_request(data)
                .then((s) => {
                    if (s.status === 0) {
                        this.$toast.success('修改成功!')
                        this.get_storeroom_details()
                        this.edit = false
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        add_details() {
            this.$store.commit('true_loading')
            let data = {}
            data = {
                enterprise_id: this.details.enterprise_id,
                name: this.details.name,
                region: this.details.region,
                address: this.details.address,
                note: this.details.note,
                person_id: this.details.person_id,
                phone: this.details.phone
            }
            storeroom_add_request(data)
                .then((s) => {
                    if (s.status === 0) {
                        this.$toast.success('添加成功!')
                        this.id = s.result.id
                        this.get_storeroom_details()
                        this.edit = false
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => this.$store.commit('false_loading'))
        }
    },
    filters:{},
    props:{},
    created(){
        this.get_all_enterprise()
        this.get_all_staff()

        this.id = this.$route.query.id;
        if(this.id) {
            this.get_storeroom_details()
        }
        else
        {
            this.edit = true
        }
    },
    destoryed(){},
    mounted(){},
    updated(){},
    components:{
        LoadingComponent,
        NavBarComponent,
        SelectComponent,
        NumberKeyboardComponent
    },
    watch:{}
}
</script>

<style>
.sub_button {
    margin: 0.5rem;
}
</style>